
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="fixed top-0 left-0 right-0 z-50 backdrop-blur-md bg-white/90 shadow-sm">
        <Navbar />
      </div>
      <div className="flex-grow mt-[60px]">  {/* Reduced from 72px to 60px for tighter spacing */}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
