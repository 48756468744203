import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { name: "Solutions", href: "/#solutions" },
    { name: "Technology", href: "/#technology" },
    { name: "About Us", href: "/#about" },
    { name: "Resources", href: "/resources" },
    { name: "Contact", href: "/contact" },
  ];

  return (
    <header 
      className={`fixed top-0 left-0 right-0 w-full z-50 transition-all duration-300 ${
        scrolled ? "bg-white shadow-md py-2" : "bg-white/80 backdrop-blur-md py-3"
      } border-b`}
    >
      <div className="container-custom flex items-center justify-between">
        <Link to="/" className="flex items-center space-x-2">
          <span className="text-3xl font-extrabold text-gray-900">Veloq</span>
        </Link>
        
        {/* Desktop Navigation */}
        <nav className="hidden lg:flex items-center gap-8">
          <Link to="/" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            Home
          </Link>
          <Link to="/ecommerce" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            eCommerce
          </Link>
          <Link to="/fulfilment" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            Fulfilment
          </Link>
          <Link to="/last-mile" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            Last Mile
          </Link>
          <Link to="/supply-chain" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            Supply Chain
          </Link>
          <Link to="/resources" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            Resources
          </Link>
          <Link to="/contact" className="font-medium text-foreground hover:text-rohlik-green transition-colors">
            Contact
          </Link>
        </nav>
        
        <div className="hidden md:block">
          <Link to="/contact" className="btn-accent">
            Request a Demo
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden" 
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-white border-b shadow-lg">
          <div className="py-4 px-4 flex flex-col space-y-4">
            <Link 
              to="/" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              Home
            </Link>
            <Link 
              to="/ecommerce" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              eCommerce
            </Link>
            <Link 
              to="/fulfilment" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              Fulfilment
            </Link>
            <Link 
              to="/last-mile" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              Last Mile
            </Link>
            <Link 
              to="/supply-chain" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              Supply Chain
            </Link>
            <Link 
              to="/resources" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              Resources
            </Link>
            <Link 
              to="/contact" 
              className="font-medium py-2 text-foreground hover:text-rohlik-green transition-colors"
              onClick={toggleMenu}
            >
              Contact
            </Link>
            <Link 
              to="/contact" 
              className="btn-accent text-center"
              onClick={toggleMenu}
            >
              Request a Demo
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
