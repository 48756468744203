import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-slate-50 pt-16 pb-8 border-t">
      <div className="container-custom">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-12">
          <div className="md:col-span-2">
            <Link to="/" className="flex items-center space-x-2 mb-4">
              <span className="text-2xl font-bold text-rohlik-green">Veloq</span>
            </Link>
            <p className="text-muted-foreground mb-4 max-w-md">
              Built for Groceries, Powered by Innovation. End-to-end technology solutions for modern grocery retail.
            </p>
          </div>
          
          <div>
            <h3 className="font-bold text-lg mb-4">Solutions</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/ecommerce" className="text-muted-foreground hover:text-rohlik-green transition-colors">
                  eCommerce Platform
                </Link>
              </li>
              <li>
                <Link to="/fulfilment" className="text-muted-foreground hover:text-rohlik-green transition-colors">
                  Fulfilment System
                </Link>
              </li>
              <li>
                <Link to="/last-mile" className="text-muted-foreground hover:text-rohlik-green transition-colors">
                  Last Mile Delivery
                </Link>
              </li>
              <li>
                <Link to="/supply-chain" className="text-muted-foreground hover:text-rohlik-green transition-colors">
                  Supply Chain
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="font-bold text-lg mb-4">Company</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/" className="text-muted-foreground hover:text-rohlik-green transition-colors">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-muted-foreground hover:text-rohlik-green transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t pt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="container-custom flex flex-col md:flex-row items-center justify-between text-sm text-muted-foreground">
            <p className="mb-2 md:mb-0">
              &copy; {currentYear} Veloq. Built for Groceries, Powered by Innovation.
            </p>
            <div className="mt-4 md:mt-0 flex space-x-4">
              <Link to="/privacy" className="text-sm text-muted-foreground hover:text-rohlik-green transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-sm text-muted-foreground hover:text-rohlik-green transition-colors">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
