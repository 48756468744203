import React, { lazy, Suspense } from 'react';
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
const Index = lazy(() => import("./pages/Index"));
const Ecommerce = lazy(() => import("./pages/Ecommerce"));
const Fulfilment = lazy(() => import("./pages/Fulfilment"));
const LastMile = lazy(() => import("./pages/LastMile"));
const Contact = lazy(() => import("./pages/Contact"));
const NotFound = lazy(() => import("./pages/NotFound"));
const SupplyChain = lazy(() => import("./pages/SupplyChain"));
const ResourcesPage = lazy(() => import("./pages/Resources"));
const BringmeisterCaseStudy = lazy(() => import("./pages/case-studies/Bringmeister"));

const queryClient = new QueryClient();

// Simple loading fallback component
const LoadingFallback = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    Loading...
  </div>
);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Sonner />
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route 
              path="/" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Index />
                </Suspense>
              } 
            />
            <Route 
              path="/ecommerce" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Ecommerce />
                </Suspense>
              } 
            />
            <Route 
              path="/fulfilment" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Fulfilment />
                </Suspense>
              } 
            />
            <Route 
              path="/last-mile" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LastMile />
                </Suspense>
              } 
            />
            <Route 
              path="/contact" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <Contact />
                </Suspense>
              } 
            />
            <Route 
              path="/supply-chain" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <SupplyChain />
                </Suspense>
              } 
            />
            <Route 
              path="/resources" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <ResourcesPage />
                </Suspense>
              } 
            />
            <Route 
              path="/resources/case-studies/bringmeister" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <BringmeisterCaseStudy />
                </Suspense>
              } 
            />
            <Route 
              path="*" 
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <NotFound />
                </Suspense>
              } 
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
